import React, { useContext } from 'react';
import { Typography } from '@mui/material';
import { ChatItem, UnreadChat } from './styled';
import { ChatsContext, chatsActions } from 'src/context/chats';
import { Stack, SxProps } from '@mui/system';
import { TChatItem } from 'src/api/chats/models';
import { getDisplayDate } from 'src/utils/dateUtils';
import Avatar from '../Dialog/Header/Avatar';
import { messagesActions } from 'src/context/messages';
import { SearchValueContext, searchValueActions } from 'src/context/searchValue';

const classes = {
  box_wrapper: {
    width: '100%',
    padding: '12px 12px 12px 20px',
  } as SxProps,
};

type ChatListItemProps = {
  chat: TChatItem;
};

export const ChatListItem = React.forwardRef<HTMLDivElement, ChatListItemProps>(({ chat }, ref) => {
  const { searchValue } = useContext(SearchValueContext);
  const { activeChat, createdActiveChat } = useContext(ChatsContext);
  const { id, name, lastMessage, unreadCount, updated, laboratoryCode, conversationAvatarUrl } = chat;
  const { created, body, title } = lastMessage || { body: '', created: '', title: '' };
  const date = getDisplayDate(updated);
  const handleClick = () => {
    if (searchValue) chatsActions.setAnchorMessage(lastMessage.created);
    chatsActions?.setActiveChat(id);
    chatsActions?.setCreatedActiveChat(created);
    chatsActions?.setActiveChatLabCode(laboratoryCode);
    messagesActions.setIsViewFoundMessagesConversation(false);
    searchValueActions?.setSearchValueConversation('');
  };

  return (
    <ChatItem
      selected={created === createdActiveChat && id === activeChat}
      onClick={handleClick}
      children={
        <Stack direction="row" sx={classes.box_wrapper} ref={ref} gap="12px">
          <Avatar name={name || ''} src={conversationAvatarUrl} />
          <Stack width="calc(100% - 145px)">
            <Typography variant="body18Bold" color={unreadCount > 0 ? 'grey.800' : 'grey.600'} noWrap lineHeight="24px">
              {name}
            </Typography>
            <Stack justifyContent="space-between" direction="row">
              <Typography
                variant="label14Medium"
                color={unreadCount > 0 ? 'grey.700' : 'grey.500'}
                lineHeight="20px"
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: '2',
                  WebkitBoxOrient: 'vertical',
                }}
              >
                {title || body}
              </Typography>
            </Stack>
          </Stack>
          <Stack justifyContent="space-between" alignItems="end">
            <Typography noWrap variant="label14Medium" color="grey.400" lineHeight="20px" sx={{ flexShrink: 0 }}>
              {date}
            </Typography>

            {unreadCount > 0 && <UnreadChat>{unreadCount}</UnreadChat>}
          </Stack>
        </Stack>
      }
    />
  );
});
